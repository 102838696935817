/* eslint-disable no-irregular-whitespace */
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import disableInFastboot from '@triptyk/ember-utils/decorators/disable-in-fastboot';

export default class Application extends Route {
  @service declare intl: IntlService;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @service declare fastboot: any;

  async beforeModel() {
    this.intl.setLocale(['fr-fr']);

    // eslint-disable-next-line no-console

    console.log(`
  Bienvenue dans les coulisses de notre site web !
  Si vous êtes ici, c'est soit parce que vous êtes un développeur curieux, soit parce que vous avez suivi le lapin blanc dans le terrier du dev.

  Dans les deux cas n'hésitez pas à nous contacter si vous avez besoin de nous, d'un stage ou d'un job.

  info@triptyk.eu

                         ,,
                        ▐██
                 ▐█▓,   ▐██      ▄▄
                 ▐████▄ ▐██  ▄▓███▀^
                 ▐██Ñ▀█████████▀"
           ,,    ▐██M,▄█████▄
          ▐██    ▐█████▀╟█████▄
          ▐██▄▄█████Ñ   ▐██ ╙███▓_
         _▄████▀╙▐██M   ▐██    ▀███▄
     _▄██████─   ▐██M   ▐██      '▀███▄
   ▓███▀▀ ▐██    ▐██M   ▐██         ╙███
    ▀     ▐██    ▐██M   ▐██
          ▐██    ▐██M
          ▐██    ▐██M
          ▐██
          ▐██
          ▐██




                                                                                                                                                                                                         
      Size matters not. Look at me. Judge me by my size, do you?
                                                                                                                                                                                                    
                                                                                                                                                                                                        
  `);
  }
  @action
  @disableInFastboot
  willTransition() {
    window.scrollTo({
      top: 0,
      behavior: 'instant' as never,
    });
  }
}
