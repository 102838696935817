import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface HeaderArgs {}

export default class HeaderComponent extends Component<HeaderArgs> {
  @tracked menuIsOpen = false;

  @action
  toggleMenu() {
    this.menuIsOpen = !this.menuIsOpen;
  }

  @action
  closeMenu() {
    this.menuIsOpen = false;
  }
}
