import { FieldType } from 'tpk-website/enums/field';
import { ServiceType } from 'tpk-website/enums/service';
import type { CaseStudySignature } from '../case-studies';

export const caseStudyTechnocite: CaseStudySignature = {
  slug: 'technocite',
  name: 'Technocité',
  projectLink: 'https://www.technocite.be',
  fields: [FieldType.Education, FieldType.Formation],
  services: [ServiceType.WebApp, ServiceType.Website],
  thumbnail:
    '/assets/images/case-study/technocite/technocite-refonte-du-site-web.webp',
  heroUrl: '/assets/images/case-study/technocite/bg-technocite-2.png',
  logo: '/assets/images/case-study/technocite/logo-technocite.svg',
  backgroundImageUrl:
    '/assets/images/case-study/technocite/bg-technocite-1.png',
  description:
    'La nouvelle plateforme de gestion et de publication des fiches de formation Technocité. Cette solution digitale sur mesure simplifie les processus de gestion, offrant une interface intuitive et performante. Elle permet également une diffusion optimisée des contenus pédagogiques.',

  goal: [
    {
      text: ` 
      <h3>NOUVEAU SITE WEB </h3>
      <p>
   
        Technocité désirait la <strong>refonte de leur site web</strong>. Cette refonte visait à <strong>améliorer l'expérience utilisateur</strong> et la <strong>structure de contenu</strong>, en intégrant une <strong>nouvelle architecture</strong> centrée sur leur cœur de métier. 
      </p>
      <p>
        Un accent particulier devait être mis sur l'<strong>amélioration visuelle</strong> du nouveau site, visant à augmenter l'attractivité de leurs formations. L'objectif était d'obtenir un <strong>meilleur taux de conversion</strong>, c'est-à-dire augmenter le nombre d'inscriptions aux formations proposées. 
      </p>
      <p>
        Il était également essentiel de <strong>clarifier</strong> et <strong>mettre en avant</strong> leurs <strong>activités</strong> pour faciliter la compréhension et l'engagement des utilisateurs.
      </p>
      `,
    },
  ],
  challenge: [
    {
      text: ` 
      <p>
        Parmi les défis rencontrés lors de la refonte du site de Technocité, le premier était la <strong>complexité</strong> de l'arborescence des formations. Les utilisateurs devaient naviguer longuement pour trouver les formations pertinentes, manquant souvent celles qui auraient pu les intéresser.
      </p>
      <p>
        Un autre défi était le <strong>manque d'uniformité</strong> dans le layout des fiches formation. Les variations selon le créateur ou le type de formation entraînaient une incohérence, et certaines formations désuètes n'étaient ni retirées ni mises à jour.
      </p>
      <p>
        Enfin, le <strong>processus d'inscriptions</strong> n'était pas géré directement par le site. Les utilisateurs devaient entrer en contact direct avec le secrétariat de Technocité, causant une surcharge de travail et interrompant le tunnel de conversion.
    </p>
    `,
      image: '/assets/images/case-study/technocite/complexite.webp',
    },
  ],
  solution: [
    {
      text: ` 
      <p>
        <strong>Problème de structure du site :</strong> 
        Pour résoudre ce problème, nous avons entièrement repensé l'expérience utilisateur en l'axant autour d'un <strong>nouveau moteur de recherche</strong>. Ce moteur permet non seulement une recherche par mots clés, mais intègre aussi un système de filtres pour orienter les visiteurs vers des formations correspondant à leurs critères d'intérêt.
      </p>
      <p>
        En complément, nous avons mis en place un système de <strong>tags</strong>. Ainsi, une recherche spécifique affiche non seulement des résultats pertinents, mais également des formations connexes qui pourraient captiver l'utilisateur en relation avec sa recherche initiale.
      </p>
      `,

      image: '/assets/images/case-study/technocite/filtre-recherche.webp',
    },
    {
      text: ` 
      <p>
        <strong>Manque d'uniformité graphique :</strong> 
        Pour répondre au challenge de l'incohérence visuelle, nous avons développé un <strong>template de formation unifié et attractif</strong>. Ce template flexible permet d'intégrer de nombreuses informations, sans rendre aucun élément obligatoire. 
      </p>
      <p>
        Afin d'améliorer l'expérience de navigation sur différents appareils, nous avons incorporé une <strong>NavBar latérale rétractable</strong>, offrant des actions rapides et accessibles en tout point de la page.
      </p>
      <p>
        Pour la création, la complétion et la mise à jour des fiches de formation, un <strong>backoffice dédié aux formateurs</strong> de Technocité a été mis en place. Ce système intuitif guide les formateurs à travers la saisie des informations, avec des champs simples à remplir, accessibles à tous, peu importe leur connaissance du numérique.
      </p>
      `,
      image:
        '/assets/images/case-study/technocite/fiche-formation-technocite-bee.webp',
    },
    {
      text: ` 
      <p>
        <strong>Processus d'inscription déstructuré :</strong> 
        Suite à l'analyse du processus d'inscription, nous avons identifié et <strong>automatisé</strong> plusieurs tâches. Les informations fréquemment manquantes sont désormais requises dès le début du processus. Enfin, le <strong>tunnel de conversion</strong> a été fluidifié, augmentant le taux d'inscriptions.
      </p>
  `,
      image:
        '/assets/images/case-study/technocite/tunnel-infos-technocite.webp',
    },
  ],
  production: [
    {
      image: '/assets/images/case-study/technocite/technocite-1.webp',
      caption:
        'Les cartes des formations et des activités ont été conçues sur mesure en prenant en compte les besoins spécifiques des personae',
    },
    {
      image: '/assets/images/case-study/technocite/back-office-technocite.webp',
      caption:
        "Malgré son développement sur mesure pour optimiser son efficacité, nous avons également veillé à ce que l'interface back-office soit esthétiquement plaisante, assurant ainsi une expérience fluide et agréable.",
    },
    {
      image: '/assets/images/case-study/technocite/formations-technocite.webp',
      caption:
        "Le back-office veille à ce que la formation soit parfaitement orchestrée, de la première étape jusqu'à la publication.",
    },
  ],
  feedback: [
    {
      video: 'https://www.youtube.com/embed/cvXsAgvxV5E?si=fV76E-1ORA4S0VCd',
    },
    {
      video: 'https://www.youtube.com/embed/AfWn-vWyYBw?si=mG7puzMng8dKAXbn',
    },
    {
      video: 'https://www.youtube.com/embed/jnsOVUXeU7U?si=pGZXgIEnJ44rpYWv',
    },
  ],
  conclusion: [
    {
      text: ` <p>Si notre collaboration avec Technocité inspire votre projet digital, prenons le temps de discuter de vos ambitions ! Si vous envisagez une refonte de votre site web, l'intégration d'un webshop ou la mise en place d'un backoffice de gestion, contactez-nous dès maintenant <a href=”mailto:info@triptyk.eu”>par email</a> ou <a href=\"tel:03227353710\">téléphone</a>.",
    </p>
    <p>'Recevez un devis sur mesure et entièrement gratuit, et ensemble, donnons vie à votre projet digital.'</p>
    `,
    },
  ],

  about: [
    {
      text: ` 
      <p>
    <strong>Technocité</strong> est un leader dans les <strong>services d'apprentissage</strong> du numérique en Wallonie, offrant des formations, des évaluations de compétences et des services médias numériques. 
  </p>
  <p>
    Qu'elles soient courtes ou qualifiantes, des <strong>formations</strong> y sont données toute l'année. Des <strong>activités</strong> sont régulièrement organisées, telles que des ateliers ou des événements. Enfin, des <strong>stages</strong> pour les jeunes de 12 à 18 ans sont ouverts durant les congés, leur permettant de participer à des programmes comme la <strong>Minecraft Education Academy</strong> et la <strong>XR Academy</strong>.
  </p>
  <p>
    Technocité s'engage activement dans des <strong>événements liés à la technologie et à l'éducation</strong>, comme des discussions sur l'<strong>intelligence artificielle</strong> et des initiatives pour encourager la participation des <strong>femmes dans les TIC</strong>. De plus, Technocité se démarque par son <strong>approche pédagogique pratique et professionnelle</strong>, comme en témoignent les retours positifs de participants à leurs formations.
  </p>
      `,

      image:
        '/assets/images/case-study/technocite/technocite-centre-competences.webp',
    },
  ],
};
