import { FieldType } from 'tpk-website/enums/field';
import { ServiceType } from 'tpk-website/enums/service';
import type { CaseStudySignature } from '../case-studies';

export const caseStudyAbex: CaseStudySignature = {
  slug: 'abex',
  name: 'abex',
  projectLink: 'https://www.abex.be',
  fields: [FieldType.Public, FieldType.Ecommerce],
  services: [ServiceType.WebApp, ServiceType.Website],
  thumbnail: '/assets/images/case-study/abex/abex-refonte-du-site.png',
  heroUrl: '/assets/images/case-study/abex/bg-abex-2.webp',
  logo: '/assets/images/case-study/abex/abex-logo-fr-1.png',
  backgroundImageUrl: '/assets/images/case-study/abex/bg-technocite-1.png',
  description:
    "Le site de l'ABEX offre une solution digitale complète pour accéder à une multitude de ressources et d'informations essentielles pour les professionnels, incluant les experts ABEX, les indices de prix, la boutique en ligne, les versions électroniques de ABEX News, ainsi que les calendriers de formations et événements.",
  goal: [
    {
      text: ` 
      <h3>NOUVEAU SITE WEB </h3>
      <p>
   
      L'Abex, l'Association Belge des Experts, a entrepris un projet de <strong>modernisation</strong> de son site web et de son outil de recherche d'experts. L'objectif principal était d'adopter une technologie plus récente pour offrir une <strong>expérience utilisateur</strong> améliorée et une <strong>recherche</strong> plus <strong>efficace</strong> des experts disponibles en Belgique.
      </p>

      `,
    },
  ],
  challenge: [
    {
      text: ` 
      <p>
      L'<strong>outil</strong> de recherche <strong>existant</strong> présentait des lacunes majeures, notamment des <strong>temps</strong> de recherche excessivement <strong>longs</strong>, nécessitant <strong>plusieurs minutes</strong> pour parcourir la base de données des experts. Cette <strong>inefficacité</strong> entraînait une <strong>mauvaise expérience utilisateur</strong> et <strong>limitait</strong> la capacité d'Abex à fournir rapidement des <strong>services</strong> à ses clients.
      </p>
      <p>La <strong>recherche</strong> sur le site était malheureusement peu conviviale et quelque peu <strong>confuse</strong>, ce qui pouvait rendre l'expérience des utilisateurs <strong>frustrante</strong> </p>
  
    `,
      image: '/assets/images/case-study/abex/abex-old-site.webp',
    },
  ],
  solution: [
    {
      text: ` 
      <p>
      Pour relever ce défi nous avons entrepris une <strong>refonte</strong> complète du site web et de son <strong>outil</strong> de recherche d'experts. En collaborant avec notre<strong> équipe</strong> de développement <strong>expérimentée</strong>, l'Abex a opté pour une technologie de pointe, offrant une <strong>interface</strong> utilisateur <strong>intuitive</strong> et des <strong>performances optimales</strong>.
      </p>
     
      `,

      image: '/assets/images/case-study/abex/abex-new-search.webp',
    },
    {
      text: ` 
      <p>
      Grâce à cette <strong>modernisation</strong>, l'Abex a considérablement <strong>amélioré</strong> l'efficacité de son outil de <strong>recherche</strong> d'experts. Les <strong>temps</strong> de recherche ont été <strong>réduits</strong> de manière significative, permettant aux utilisateurs de trouver un expert le mieux adaptés à leurs <strong>besoins</strong>. Cette amélioration a <strong>renforcé</strong> la <strong>réputation</strong> d'Abex en tant qu'acteur majeur dans le domaine de la consultation et de l'expertise en Belgique.
      </p>
      `,
      video: '/assets/images/case-study/abex/abex-search-speed.mp4',
    },
    {
      text: ` 
      <p>
     Notre équipe a également développé et mis en place un <strong> système d'e-commerce</strong>  innovant pour la vente de bordereaux de prix unitaire, de codes de mesurage de surface et d'autres ouvrages essentiels. Cette solution <strong> sur mesure</strong>  a permis à ABEX <strong> d'améliorer</strong>  significativement son processus de <strong> vente en ligne</strong> , offrant une expérience utilisateur <strong> fluide</strong>  et <strong> efficace</strong> .
      </p>
  `,
      image: '/assets/images/case-study/abex/abex-basket.webp',
    },
  ],
  production: [
    {
      image: '/assets/images/case-study/abex/abex-real1.webp',
    },
    {
      image: '/assets/images/case-study/abex/abex-real2.webp',
    },
    {
      image: '/assets/images/case-study/abex/abex-real3.webp',
    },
  ],
  feedback: [],
  conclusion: [
    {
      text: ` <p>Si notre collaboration avec L'ABEX inspire votre projet digital, n'hésitez pas à nous contacter pour échanger sur vos objectifs ! Que vous envisagiez une modernisation de votre site internet, l'ajout d'une boutique en ligne ou la création d'un système de gestion en backoffice, nous sommes là pour vous accompagner. Contactez-nous dès à présent pour concrétiser vos idées <a href=”mailto:info@triptyk.eu”>par email</a> ou <a href=\"tel:03227353710\">téléphone</a>.",
    </p>
    <p>'Recevez un devis sur mesure et entièrement gratuit, et ensemble, donnons vie à votre projet digital.'</p>
    `,
    },
  ],

  about: [
    {
      text: ` 
      <p>
      L'ABEX, ou <strong>Association Belge des Experts</strong>, est une union professionnelle regroupant des experts <strong>spécialisés</strong> dans divers <strong>secteurs</strong> tels que le bâtiment & la construction, l'immobilier et le juridique. Cette association propose des <strong>formations</strong> et édite <strong>l'indice</strong> ABEX, qui indique l'évolution du <strong>coût</strong> de la <strong>construction</strong> d'habitations et de logements privés.</p> <p>L'ABEX publie un <strong>trimestrie</strong> intitulé <strong>«&nbsp;ABEX news&nbsp;»</strong> à destination des experts et des donneurs d'ordre.</p><p>Les<strong> membres</strong> de l'ABEX sont encouragés à <strong>mentionner</strong> leur <strong>appartenance</strong> à l'association lors de leurs <strong>missions</strong>. De plus, les <strong>membres</strong> peuvent apposer une <strong>plaque</strong> « Expert ABEX » à l'extérieur de leur <strong>habitation</strong>.</p> <p>L'ABEX met en place des <strong>règles</strong> de déontologie et un Conseil de Discipline chargé de juger et <strong>sanctionner</strong> les membres en cas de <strong>violation </strong>des prescriptions statutaires et <strong>réglementaires</strong>.
  </p>
      `,

      image: '/assets/images/case-study/abex/technocite-mons.webp',
    },
  ],
};
