import { FieldType } from 'tpk-website/enums/field';
import { ServiceType } from 'tpk-website/enums/service';
import type { CaseStudySignature } from '../case-studies';

export const caseStudyCrehopa: CaseStudySignature = {
  slug: 'crehopa',
  name: 'crehopa',
  projectLink: 'https://www.crehopa.be',
  fields: [FieldType.Ecommerce],
  services: [ServiceType.WebApp],
  thumbnail: '/assets/images/case-study/crehopa/crehopa.png',
  heroUrl: '/assets/images/case-study/crehopa/bg-crehopa.png',
  logo: '/assets/images/case-study/crehopa/logo-crehopa.svg',
  backgroundImageUrl: '/assets/images/case-study/crehopa/bg-crehopa-1.png',
  description:
    "Crehopa, devait relever le défi d'offrir une expérience d'achat fluide et performante à ses clients professionnels et créateurs. Notre équipe a répondu à cette problématique en concevant une plateforme web sur-mesure, alliant fonctionnalités avancées et ergonomie intuitive.",

  goal: [
    {
      text: ` 
      <h3>GESTION DES PRODUITS ET DES STOCKS</h3>
      <p>Crehopa, conscient des défis liés à la <strong>gestion</strong> efficace de ses <strong>stocks</strong> et à la nécessité de rendre ses produits plus accessibles, a exprimé la <strong>nécessité</strong> d'une <strong>solution digitale</strong> innovante pour répondre à ces besoins.</p>
<p>
   
      L'objectif central était de développer une <strong>solution digitale</strong> intuitive et conviviale qui simplifierait la <strong>gestion des stocks</strong> tout en permettant à Crehopa de présenter ses produits de manière attrayante aux clients potentiels.</p>
      <p>
      
      Cette initiative visait à <strong>fournir</strong> à Crehopa un <strong>moyen</strong> efficace de <strong>gérer</strong> ses opérations quotidiennes tout en mettant en avant son <strong>catalogue</strong> de produits. 
      </p>
      `,
    },
  ],
  challenge: [
    {
      text: ` 
      <p>
      Lors du développement de la <strong>solution digitale</strong> pour Crehopa, plusieurs <strong>défis</strong> majeurs ont été identifiés. Tout d'abord, le système de <strong>gestion</strong> des stocks existant ne permettait pas de créer efficacement des <strong>variantes de produits</strong>. Par exemple, un même produit décliné en différentes couleurs était traité comme des produits distincts, obligeant à saisir à plusieurs reprises les informations communes et à gérer chaque variante <strong>individuellement</strong>. Cette inefficacité entraînait une <strong>duplication</strong> des <strong>efforts</strong> et une <strong>perte de temps</strong> considérable pour l'équipe de Crehopa.
</p><p>
      Un autre défi majeur était la nécessité de rationaliser et d'uniformiser la présentation des produits sur la plateforme en ligne. La diversité des formats et des styles de présentation rendait l'expérience utilisateur <strong>incohérente</strong>, ce qui pouvait entraîner une <strong>confusion</strong> chez les clients potentiels et nuire à la perception globale de la <strong>marque</strong>.
      </p><p>
      
      Ces défis ont motivé la conception et le développement d'une <strong>solution digitale</strong> sur mesure pour Crehopa, visant à <strong>rationaliser</strong> la gestion des stocks, à <strong>uniformiser</strong> la présentation des produits et à intégrer un <strong>processus</strong> d'achat <strong>fluide</strong> directement sur la plateforme en ligne. Le but était de fournir à Crehopa un <strong>outil</strong> complet et <strong>efficace</strong> pour optimiser ses opérations et offrir une <strong>expérience</strong> client <strong>exceptionnelle</strong>.
    </p>
    `,
      image: '/assets/images/case-study/crehopa/bijoux.webp',
    },
  ],
  solution: [
    {
      text: ` 
      <p><strong>Une Solution Digitale pour Améliorer les Performances:</strong> Notre <strong>solution digitale</strong> a permis d'optimiser les <strong>performances</strong> du site web, résolvant ainsi le problème de <strong>recherche</strong> lente des produits. Les utilisateurs peuvent désormais trouver rapidement les produits qu'ils cherchent, <strong>améliorant </strong>ainsi leur <strong>expérience</strong> globale.</p>
     
      `,
    },
    {
      text: ` 
      <p><strong>Sécurité et Contrôle d'Accès:</strong> Pour protéger les <strong>informations sensibles</strong>, nous avons mis en place un <strong>système</strong> de demande <strong>d'accès</strong> pour les utilisateurs non-connectés. Une fois connectés, les utilisateurs peuvent <strong>sélectionner</strong> les produits de leur choix et les ajouter à leur panier, tout en ayant la possibilité d'appliquer des codes <strong>promo</strong>.</p>
      <p><strong>Gestion des Commandes et des Livraisons:</strong>
      Notre <strong>solution digitale</strong> permet aux utilisateurs de choisir le type de livraison et de <strong>visualiser le coût</strong> associé. Le total de la commande est récapitulé, permettant une <strong>validation sécurisée</strong>. Nous avons également mis en place une <strong>intégration</strong> avec un outil qui <strong>transmet</strong> les <strong>commandes</strong> aux gestionnaires de CREHOPA, assurant une <strong>gestion efficace</strong> des commandes.</p>
      <p><strong>Intégration avec des Outils de Gestion:</strong>
      Notre <strong>base de données</strong> communique avec un outil qui met à jour les <strong>informations</strong> sur les <strong>produits</strong> et ajoute des codes promos, garantissant ainsi une <strong>synchronisation</strong> parfaite entre le <strong>site web</strong> et les <strong>systèmes</strong> de <strong>gestion</strong> de CREHOPA.</p>
      `,
    },
    {
      text: ` 
      <p>
      Grâce à cette <strong>solution digitale</strong>, CREHOPA a pu <strong>améliorer</strong> significativement <strong>l'expérience client</strong>, réduire les temps de <strong>recherche</strong> et <strong>optimiser</strong> ses <strong>processus</strong>. Notre équipe est fière d'avoir pu apporter une<strong> valeur ajoutée</strong> à cette entreprise en lui offrant une <strong>solution digitale innovante</strong> et <strong>efficace</strong>.

      </p>
  `,
    },
  ],
  production: [
    {
      image: '/assets/images/case-study/crehopa/catalogue.webp',
      caption: 'Catalogue des produits crehopa',
    },

    {
      image: '/assets/images/case-study/crehopa/product.webp',
      caption: "Détail d'un produit crehopa",
    },
  ],
  feedback: [],
  conclusion: [
    {
      text: ` <p>Si notre collaboration avec crehopa inspire votre projet digital, prenons le temps de discuter de vos ambitions ! Si vous envisagez une refonte de votre site web, l'intégration d'un webshop ou la mise en place d'un backoffice de gestion, contactez-nous dès maintenant <a href=”mailto:info@triptyk.eu”>par email</a> ou <a href="tel:03227353710">téléphone</a>."
    </p>
    <p>'Recevez un devis sur mesure et entièrement gratuit, et ensemble, donnons vie à votre projet digital.'</p>
    `,
    },
  ],

  about: [
    {
      text: ` 
      <p>
      <strong>Crehopa</strong> est un <strong>grossiste</strong> spécialisé dans les perles et <strong>produits</strong> de <strong>loisirs créatifs</strong>, offrant un vaste choix de produits de <strong>qualité</strong> dans un magasin bien organisé et une boutique en ligne étoffée, le tout avec un <strong>service</strong> attentionné à destination des <strong>professionnels</strong> et <strong>créateurs</strong>.
  </p>
  <p>
  En tant que grossiste, <strong>Crehopa</strong> <strong>distribue</strong> tous les <strong>produits</strong> pour la création de bijoux (Polaris, Miyuki, Swarovski, céramique, métal, bois, résine), le <strong>nécessaire</strong> pour l'emballage de cadeaux et une gamme de loisirs créatifs (pâte Fimo et Cernit, serviettes en papier, supports en bois et carton). Ils sont distributeur officiel des composants Swarovski® Elements.
  </p>
  <p>
  Leur boutique en ligne compte près de <strong>25 000 articles</strong> constamment mis à jour. Crehopa propose aussi la <strong>personnalisation</strong> de pièces en métal avec des logos. Leur site s'adresse aux professionnels et créateurs, servant de vitrine et de catalogue pour effectuer des achats. 
  </p>
      `,
    },
  ],
};
