import Component from '@glimmer/component';

interface FooterArgs {}

export default class FooterComponent extends Component<FooterArgs> {
  get currentYear() {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }
}
