import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'tpk-website/config/environment';

export default class HeadDataService extends Service {
  @tracked title = config.applicationName;
  @tracked description = 'Nouveau site de TRIPTYK';
  @tracked image = 'https://triptyk.eu/assets/images/triptyk-website.png';
  @tracked url = 'https://triptyk.eu';

  get applicationName() {
    return config.applicationName;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  // eslint-disable-next-line no-unused-vars
  interface Registry {
    'head-data': HeadDataService;
  }
}
