import { FieldType } from 'tpk-website/enums/field';
import { ServiceType } from 'tpk-website/enums/service';
import type { CaseStudySignature } from '../case-studies';

export const caseStudyBlueWhite: CaseStudySignature = {
  slug: 'bluewhite',
  name: 'BlueWhite',
  projectLink: 'https://www.bluewhite.be',
  fields: [FieldType.Education],
  services: [ServiceType.WebApp, ServiceType.Website, ServiceType.Branding],
  thumbnail: '/assets/images/case-study/bluewhite/bluewhite.png',
  heroUrl: '/assets/images/case-study/bluewhite/bg-bluewhite.png',
  logo: '/assets/images/case-study/bluewhite/logo-blue-white-white.svg',
  backgroundImageUrl: '/assets/images/case-study/bluewhite/bg-technocite-1.png',
  description:
    "L'école de natation du Bluewhite nous a mandaté pour réaliser son branding complet. Nous avons également conçu son site web et une web app pour simplifier la gestion des inscriptions et du calendrier des cours",

  goal: [
    {
      text: ` 
      <h3>BRANDING ET SITE WEB </h3>
      <p>        
Lorsqu'un nouveau président a pris les rênes de l'école de natation <strong>Blue White</strong>, une impulsion dynamique pour revoir son identité et <strong>améliorer</strong> sa présence en ligne a été déclenchée. La nécessité d'une <strong>solution digitale</strong> intégrée était claire.
      </p><p>
      Bien que située <strong>Bruxelles</strong>, Blue White a fait appel à <strong>TRIPTYK</strong> pour repenser son image et <strong>optimiser</strong> sa présence en ligne. Étant également active dans le domaine du branding, du graphisme et de l'impression, TRIPTYK a offert une <strong>solution digitale complète</strong> qui répondait parfaitement aux besoins de l'école.
      </p>
      <p>
      La <strong>demande</strong> était double : créer un <strong>logo</strong> percutant, <strong>adaptable</strong> sur différents supports tels que les bonnets, les vêtements et les sacs, tout en développant un <strong>site web</strong> permettant une <strong>gestion</strong> efficace des <strong>inscriptions</strong> des élèves en ligne.
      </p> 
      `,
    },
  ],
  challenge: [
    {
      text: ` 
      <p>       
L'un des <strong>défis</strong> majeurs de cette mission résidait dans la création d'un système de <strong>gestion</strong> des <strong>inscriptions</strong> et du <strong>calendrier</strong> non seulement fonctionnel, mais également <strong>attrayant</strong>, offrant une <strong>expérience utilisateur exceptionnelle</strong>.</p><p>L'objectif était de <strong>convaincre</strong> les utilisateurs de <strong>privilégier</strong> le <strong>site</strong> pour leurs inscriptions <strong>plutôt</strong> que de recourir au <strong>téléphone</strong>. Avec une augmentation de plus de 300% du nombre d'inscrits, l'école ne pouvait <strong>plus</strong> se permettre de <strong>gérer manuellement</strong> chaque inscription, d'où la nécessité d'une <strong>solution digitale efficace</strong>.
      </p>
      
     
    `,
      image: '/assets/images/case-study/bluewhite/bluewhite.png',
    },
    {
      text: ` <p>
      Un <strong>autre défi</strong> résidait dans l'optimisation de l'apparence du calendrier sur les appareils <strong>mobiles</strong>. Près de 50% des utilisateurs prévoyaient de réaliser leurs inscriptions et de consulter les horaires des cours via leur smartphone. Il était donc <strong>impératif</strong> de concevoir une <strong>solution digitale</strong> efficace offrant une expérience de lecture <strong>claire et intuitive</strong> sur les petits écrans de <strong>téléphone</strong> tout en sachant que l'école allait ajouter, supprimer et modifier des éléments du calendrier au fil du temps.
      </p>      
    `,
      image: '/assets/images/case-study/bluewhite/calendrier.webp',
    },
  ],
  solution: [
    {
      text: ` 
      <p>
        <strong>Gestion des inscriptions&nbsp;:</strong><br>
        Pour résoudre efficacement les <strong>problèmes</strong> d'affichage du <strong>calendrier</strong>, nous avons élaboré <strong>deux approches</strong> distinctes pour améliorer <strong>l'expérience utilisateur</strong>, selon qu'elle soit sur <strong>ordinateur</strong> ou sur <strong>mobile</strong>. Cette stratégie garantit une <strong>optimisation</strong> totale pour les deux plateformes, <strong>sans compromis</strong>. <strong>Grâce</strong> à la <strong>gestion</strong> agile du code et des composants des applications par <strong>TRIPTYK</strong>, cette dualité a été rendue <strong>possible</strong>, assurant ainsi une expérience utilisateur fluide et <strong>intuitive</strong>, quel que soit le dispositif utilisé.
      </p>
   
      `,

      image: '/assets/images/case-study/bluewhite/calendrier2.webp',
    },
    {
      text: ` 
      <p>
      Afin d'inciter les <strong>utilisateurs</strong> à <strong>opter</strong> pour la plateforme <strong>en ligne</strong>, une <strong>fonctionnalité</strong> a été introduite permettant de <strong>consulter</strong> les <strong>horaires</strong> des groupes et de vérifier la <strong>disponibilité</strong> des places.</p><p> De plus, il est possible de s'inscrire sur une <strong>liste d'attente</strong> pour les groupes complets, assurant ainsi une <strong>intégration automatique</strong> au groupe en cas de désistement. Cette initiative vise à rendre l'expérience utilisateur plus <strong>pratique</strong> tout en <strong>maximisant</strong> les <strong>chances d'inscription</strong> au groupe souhaité.
    </p>
      
      <p>Une <strong>gestion</strong> complète d'envoi <strong>d'emails</strong> a été mise en place pour <strong>informer</strong> à la fois les <strong>inscrits</strong> et les <strong>administrateurs</strong>, évitant ainsi à chacune des parties de devoir se rendre sur la plateforme pour obtenir ces informations.</p>
     
      `,
      image: '/assets/images/case-study/bluewhite/calendrier3.webp',
    },
    {
      text: ` 
      <p>
      Pour le volet <strong>print</strong>, nous avons conçu un <strong>branding</strong> complet comprenant un <strong>logo</strong> distinctif, des <strong>flyers</strong>, des <strong>affiches</strong>, des drapeaux et des impressions textiles pour <strong>promouvoir</strong> le club de natation de manière optimale. Une attention particulière a été accordée à l'image et aux couleurs du club afin que ces <strong>supports promotionnels</strong> se démarquent facilement, que ce soit à la piscine ou dans la rue, garantissant ainsi une <strong>reconnaissance</strong> efficace parmi d'autres.
      </p>
  `,
      image: '/assets/images/case-study/bluewhite/print-bluewhite.webp',
    },
  ],
  production: [
    {
      image: '/assets/images/case-study/bluewhite/bluewhite-tarifs.webp',
      caption: 'Une gestion aisée du site web via le CMS Wordpress',
    },
    {
      image: '/assets/images/case-study/bluewhite/bluewhite-calendrier.webp',
      caption:
        "Un calendrier efficace et responsive qui permet de visualiser l'horaire des cours, de vérifier s'ils sont complets ou non, et de s'y inscrire",
    },
    {
      image:
        '/assets/images/case-study/bluewhite/bluewhite-gestion-inscriptions.webp',
      caption: 'Le back-office permet la gestion des inscriptions.',
    },
    {
      image:
        '/assets/images/case-study/bluewhite/bluewhite-interface-gestion.webp',
      caption:
        "Le back-office offre la possibilité de gérer les personnes en liste d'attente, ainsi que de démarrer ou suspendre les inscriptions.",
    },

    {
      image: '/assets/images/case-study/bluewhite/bluewhite-sweater.webp',
      caption:
        "Un exemple de sweater porté par les moniteurs lors qu'ils donnent cours.",
    },
  ],
  feedback: [],
  conclusion: [
    {
      text: ` <p>Si l'expérience du Blue White vous motive pour votre projet de solution digitale, n'hésitez pas à nous contacter pour explorer ensemble vos objectifs ! Que ce soit pour moderniser votre site web, intégrer une boutique en ligne ou mettre en place un système de gestion avancé, nous sommes là pour vous accompagner. Prenez dès maintenant le premier pas vers une transformation digitale réussie en nous contactant. <a href=”mailto:info@triptyk.eu”>par email</a> ou <a href=\"tel:03227353710\">téléphone</a>.",
    </p>
    <p>'Recevez un devis sur mesure et entièrement gratuit, et ensemble, donnons vie à votre projet digital.'</p>
    `,
    },
  ],

  about: [
    {
      text: ` 
      <p>Le <strong>Blue White</strong> est une <strong>école de natation</strong> située à Evere, offrant des <strong>cours</strong> pour les enfants de 4 à 12 ans ainsi que des cours de <strong>perfectionnement</strong> pour les plus de 12 ans. Fondé en 2005, le Club Blue White asbl avait pour objectif d'allier la natation aux sports d'hiver. En 2012, l'école de natation a été <strong>reprise</strong> par une nouvelle équipe dynamique qui a insufflé un <strong>nouvel essor</strong>. Les professeurs, tous diplômés en éducation physique, mettent leur <strong>expertise pédagogique</strong> au service des enfants depuis plus de 10 ans.</p>
      <p>Au Blue White, les enfants apprennent bien plus que la natation; ils apprennent à se dépasser, à <strong>surmonter</strong> les obstacles et à acquérir une <strong>confiance en soi</strong> essentielle. Les moniteurs compétents et passionnés accompagnent les <strong>enfants</strong> à leur <strong>rythme</strong>, les aidant à franchir leurs propres limites. Cette école de natation offre une <strong>expérience</strong> enrichissante où l'effort et la confiance en soi sont valorisés pour permettre aux enfants de <strong>progresser</strong>.</p>
      `,
    },
  ],
};
